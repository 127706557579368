import React, { useState } from 'react';

export const QuestContext = React.createContext({
  quest: '',
  setQuest: () => {},
  questModal: false,
  setQuestModal: () => {},
  instructions: false,
  setInstructions: () => {}
});

const QuestContextProvider = ({ children }) => {
  const [quest, setQuest] = useState('');
  const [questModal, setQuestModal] = useState(false)
  const [help, setHelp] = useState(false)
  const [instructions, setInstructions] = useState(false)

  return (
    <QuestContext.Provider value={{ 
      help: help, 
      setHelp: setHelp, 
      quest: quest, 
      setQuest: setQuest, 
      questModal: questModal, 
      setQuestModal: setQuestModal, 
      instructions: instructions,
      setInstructions: setInstructions
      }}>
      {children}
    </QuestContext.Provider>
  );
};

export default QuestContextProvider;